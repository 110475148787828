<template>
  <div>
    <create-doctor
      fetchClinicsBy="admin" />
  </div>
</template>

<script>

import createDoctor from '../../components/doctors/createDoctor.vue'

export default {
  components: {
      createDoctor
  },
  data:()=>({
  })
}
</script>
